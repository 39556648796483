<template>
  <div class="login_page">
    <!-- 语言转化 -->
    <div style="position: absolute; top: 20px; right: 55px">
      <el-dropdown @command="handlanguage">
        <span class="el-dropdown-link" style="width: 112px; display: inline-block">
          {{ language.language }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="CHS">简体中文</el-dropdown-item>
          <el-dropdown-item command="TC">繁體中文</el-dropdown-item>
          <el-dropdown-item command="EN">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="login_box">
      <!-- 项目名 -->
      <div class="login_title">
        <div v-if="!showtitle">{{ language.logo }}</div>
        <div v-if="showtitle">{{ language.logo }}{{ language.title2 }}</div>
      </div>
      <div class="input_box">
        <!-- 左侧图片 -->
        <div>
          <img class="loginbaximg" src="../static/loginbaximg.png" alt="" />
        </div>
        <!-- 右侧类容 -->
        <div
          style="
            padding: 0 33px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 300px;
          "
        >
          <div class="inputtitle">{{ language.User_Login }}</div>
          <!-- 账号输入 -->
          <div
            style="
              position: relative;
              margin-top: 20px;
              border: 1px solid rgb(231 231 231);
              border-radius: 6px;
              width: 100%;
            "
          >
            <span style="" class="iconeye"></span>
            <el-input
              style="padding-left: 15px; box-sizing: border-box"
              v-model="username"
              :placeholder="language.Account"
            ></el-input>
          </div>
          <!-- 密码输入 -->
          <div
            style="
              position: relative;
              margin-top: 20px;
              border: 1px solid rgb(231 231 231);
              border-radius: 6px;
              width: 100%;
            "
          >
            <span style="" class="iconeye2"></span>
            <el-input
              style="padding-left: 15px; box-sizing: border-box"
              v-model="password"
              :type="passwordicon ? 'password' : 'text'"
              :placeholder="language.Password"
            ></el-input>
            <span v-if="passwordicon" style="" @click="eyebtn()" class="iconeye3"></span>
            <span v-else style="" @click="eyebtn()" class="iconeye4"></span>
          </div>
          <!-- 验证码 -->
          <!-- <div style="margin-top:20px;display:flex;width: 100%;"> -->
          <!-- 验证码输入框 -->
          <!-- <div style="border: 1px solid rgb(231 231 231);flex:3;border-radius: 6px;">
              <el-input type="text" v-model="VerificationCode" :placeholder="language.VerificationCode"></el-input>
            </div> -->
          <!-- 验证码图片 -->
          <!-- <div style="flex:2">

            </div> -->
          <!-- </div> -->
          <!-- 登录按钮 -->
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              border: none;
              margin-top: 20px;
            "
          >
            <el-button
              style="
                width: 100%;
                border-radius: 10px;
                box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
              "
              type="primary"
              @click="Login()"
              >{{ language.Login }}</el-button
            >
          </div>
          <!-- 忘记密码及注册 -->
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-top: 20px;
              font-size: 13px;
              cursor: pointer;
            "
          >
            <div @click="retrievepassword()">{{ language.ForgotPassword }}</div>
            <div @click="register()">{{ language.RegisteredAccount }}</div>
          </div>
          <!-- 协议按钮 -->
          <div
            style="
              margin-top: 20px;
              width: 100%;
              border-top: 1px dashed #7c7c7c;
              position: relative;
              display: flex;
              justify-content: center;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                position: absolute;
                top: -10px;
                background: #fff;
                padding: 0 20px;
              "
            >
              <div style="color: #a1a1a1; font-size: 13px">
                <span
                  @click="theentryagreement()"
                  style="cursor: pointer; color: #7c7c7c"
                  >{{ language.theentryagreement }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        :title="language.EntryAgreement"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <div v-html="settleInExplain"></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ language.determine }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import rules from "../common/js/rules";
import config from "../api/config";
import { login, info, explaingetConfig, groupgetRuleTreeList } from "../api/index.js";
export default {
  data() {
    return {
      language: "", //语言
      username: "", //账号
      password: "", //密码
      passwordicon: true, //显示密码
      showtitle: true,
      dialogVisible: false, //协议显示隐藏
      settleInExplain: "", //协议内容
      rules: "",
      // VerificationCode:""//验证码
    };
  },
  created() {
    if (config.BaseUrl.indexOf("test") > 0) {
      this.showtitle = true;
    } else {
      this.showtitle = false;
    }
    //语言判断
    this.changelanguage();
  },
  mounted() {
    this.init();
  },
  methods: {
    // 页面初始，获取协议
    init() {
      explaingetConfig().then((res) => {
        this.settleInExplain = res.data.settleInExplain;
        this.settleInExplain = this.settleInExplain.replace(/&amp;/g, "&");
        this.settleInExplain = this.settleInExplain.replace(/&lt;/g, "<");
        this.settleInExplain = this.settleInExplain.replace(/&gt;/g, ">");
        this.settleInExplain = this.settleInExplain.replace(/&nbsp;/g, " ");
        this.settleInExplain = this.settleInExplain.replace(/&#39;/g, "'");
        this.settleInExplain = this.settleInExplain.replace(/&quot;/g, '"');
      });
    },
    //获取配置语言
    changelanguage() {
      this.language = this.$units();
      document.title = "EPAY" + this.language.Merchantbackend;
    },
    //密码隐藏及显示
    eyebtn() {
      this.passwordicon = !this.passwordicon;
    },
    //登录按钮
    Login() {
      if (!this.username) {
        this.$message({
          message: this.language.nousername,
          type: "warning",
        });
        return;
      }
      if (!this.password) {
        this.$message({
          message: this.language.nopassword,
          type: "warning",
        });
        return;
      }
      // if(!this.VerificationCode){
      //   this.$message({
      //     message: this.language.noVerificationCode,
      //     type: 'warning'
      //   });
      //   return
      // }
      window.localStorage.clear();
      let data = {
        account: this.username,
        password: this.password,
        device: "1",
      };
      login(data).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
          localStorage.setItem("token", res.data.userinfo.token);
          if (res.data.userinfo.pid == 0) {
            this.userinfo();
          } else {
            let params = {
              groupId: res.data.userinfo.group_id,
            };
            groupgetRuleTreeList({}, params).then((res2) => {
              localStorage.setItem("adminNode", JSON.stringify(res2.data));
              this.$router.push(rules.rules().list[0]);
            });
            // this.$router.push("/");
          }
        }
      });
    },
    // 打开协议
    theentryagreement() {
      this.dialogVisible = true;
    },
    // 判断绑定
    userinfo() {
      info().then((res) => {
        if (res.code == 1) {
          if (res.data) {
            if (res.data.status == 1 || res.data.status == 3) {
              this.$store.commit("changeinfo", true);
              this.$router.push("/merchantcenter");
            } else {
              this.$router.push("/");
            }
            // this.$router.push("/");
          } else {
            this.$store.commit("changeinfo", true);
            this.$router.push("/merchantcenter");
          }
        }
      });
    },
    // 前往注册
    register() {
      this.$router.push("/register");
    },
    // 前往忘记密码
    retrievepassword() {
      this.$router.push("/retrievepassword");
    },
    // 切换语言
    handlanguage(command) {
      if (command == "CHS") {
        this.language = "简体中文";
      } else if (command == "TC") {
        this.language = "繁體中文";
      } else if (command == "EN") {
        this.language = "English";
      }
      this.$store.commit("changelanguage", command);
      this.changelanguage();
      // this.$message('click on item ' + command);
    },
  },
};
</script>
<style scoped>
.login_page {
  width: 100%;
  height: 100%;
  background-image: url("../static/loginpagebg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login_title {
}

.login_title > div:first-child {
  font-family: SourceHanSansCN-Regular;
  font-size: 30px;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 0;
  color: #fff;
  line-height: 117px;
  text-align: center;
}

.bg1 {
  display: inline-block;
  width: 74px;
  height: 74px;
  background: #fff;
  opacity: 0.1;
  border-radius: 0 74px 0 0;
  position: absolute;
  left: 0;
  top: 43px;
}

.bg2 {
  display: inline-block;
  width: 94px;
  height: 94px;
  background: #fff;
  opacity: 0.1;
  border-radius: 50%;
  position: absolute;
  right: -16px;
  top: -16px;
}

.input_box {
  background: #fff;
  padding: 10px;
  display: flex;
  box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
}

.loginbaximg {
  width: 500px;
  height: 413px;
}

.inputtitle {
  font-weight: 600;
  font-size: 24px;
}

* >>> .el-input__inner {
  border: none;
  border-radius: 0;
}

.iconeye {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 8px;
  background: url("../static/icon-login.png") no-repeat -54px 0;
}

.iconeye2 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 8px;
  background: url("../static/icon-login.png") no-repeat -106px 0;
}

.iconeye3 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 8px;
  cursor: pointer;
  right: 0;
  background: url("../static/icon-login.png") no-repeat 0 -43px;
}

.iconeye4 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 8px;
  cursor: pointer;
  right: 0;
  background: url("../static/icon-login.png") no-repeat -55px -43px;
}

.verification {
  border-bottom: none !important;
  cursor: pointer;
}

.verificationno {
  color: red;
}

.verificationok {
  color: #00a81a;
}

.mb {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgb(201 201 201 / 51%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mbbox {
  background: #fff;
  padding: 100px;
  border-radius: 30px;
}

* >>> input {
  box-shadow: 0 0 0px 1000px white inset !important;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
